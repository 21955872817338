.work {
  margin-top: -6rem;
  padding: 0 6rem;
  position: relative;
  z-index: 1;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 30rem;
    margin: 0 0 2rem 0;
    text-decoration: none;
    text-align: center;
    padding: 3rem;
    box-sizing: border-box;
    transition: all 0.4s $easeInOutCubic;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      z-index: -2;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(100%);
      transition: all 0.4s $easeInOutCubic;
      z-index: -1;
    }

    &:hover {
      color: $white;

      &:before {
        transform: translateY(0);
      }
    }

    h3 {
      font-size: 2.6rem;
      font-weight: 600;
      margin: 0;
    }

    &--large {
      height: 50rem;

      @media (max-width: $viewport-md) {
        height: 30rem;
      }
    }

    &--medium {
      height: 39rem;
      margin-top: 11rem;

      @media (max-width: $viewport-md) {
        height: 30rem;
        margin-top: 0;
      }
    }

    &--yellow {
      border: 2px solid $yellow;
      color: $yellow;

      &:before {
        background-color: $yellow;
      }
    }

    &--green {
      border: 2px solid $green;
      color: $green;

      &:before {
        background-color: $green;
      }
    }

    &--blue {
      border: 2px solid $blue;
      color: $blue;

      &:before {
        background-color: $blue;
      }
    }
  }

  &__thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2;
  }

  &__title {
    position: relative;
  }
}
