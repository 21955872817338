.case {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: lighten($black, 98);
  z-index: -1;
  overflow-y: scroll;
  opacity: 0;
  transform: translateY(10rem);
  transition: all 0.5s $easeInOutCubic;
  -webkit-overflow-scrolling: touch;

  &--active {
    opacity: 1;
    z-index: 5;
    transform: translateY(0);

    & + .case__close {
      z-index: 5;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &--blue {
    & + .case__close {
      &:after {
        background-color: $blue;
      }

      .case__closeborder {
        border: 0.2rem solid $blue;
      }

      .case__closeline {
        background-color: $blue;
      }
    }

    .case__title {
      color: $blue;
    }
  }

  &--green {
    & + .case__close {
      &:after {
        background-color: $green;
      }

      .case__closeborder {
        border: 0.2rem solid $green;
      }

      .case__closeline {
        background-color: $green;
      }
    }

    .case__title {
      color: $green;
    }
  }

  &__close {
    display: block;
    position: fixed;
    width: 5rem;
    height: 5rem;
    top: 4rem;
    right: 4rem;
    z-index: -1;
    opacity: 0;
    transform: translateY(3rem);
    transition: all 0.5s $easeInOutCubic;

    @media (max-width: $viewport-md) {
      top: 2rem;
      right: 2rem;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 8rem;
      background-color: $yellow;
      transition: all 0.4s $easeInOutCubic;
      z-index: 1;
    }

    &:hover {
      &:after {
        right: 0;
      }

      .case__closeline:before {
        width: 2rem;
      }
    }
  }

  &__closeborder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.2rem solid $yellow;
    box-sizing: border-box;
  }

  &__closeline {
    width: 2rem;
    height: 0.2rem;
    background-color: $yellow;
    position: absolute;
    left: 1.5rem;
    transition: all 0.4s $easeInOutCubic;
    z-index: 2;
    top: 2.45rem;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0.2rem;
      background-color: $white;
    }

    &--first {
      transform: rotate(-45deg);

      &:before {
        transition: all 0.3s $easeInOutCubic 0.4s;
      }
    }

    &--second {
      transform: rotate(45deg);

      &:before {
        transition: all 0.2s $easeInOutCubic 0.5s;
      }
    }
  }

  &__content {
    text-align: center;
    padding: 13rem 0;

    img {
      max-width: 100%;
      margin-bottom: 2rem;
    }

    img[src$=".gif"] {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

    iframe {
      width: 100%;
      height: 60rem;
    }
  }

  &__title {
    font-size: 3.6rem;
    font-weight: 600;
    margin: 0;
    color: $yellow;
  }

  &__subtitle {
    display: block;
    margin-bottom: 6rem;
  }
}
