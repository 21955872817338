.about {
	margin-top: -9rem;

	&__image {
		width: 100%;
		z-index: 1;
		position: relative;
		animation: aboutBlock 1s $easeInOutCubic 1.4s both;
	}

	&__description {
		border: 2px solid $yellow;
		margin: 8rem 0 0 -9rem;
		padding: 6rem 6rem 4rem 11rem;
		animation: aboutBlock 1s $easeInOutCubic 2s both;

		@media (max-width: $viewport-md) {
			margin: -8rem 0 0 0;
			padding: 12rem 4rem 4rem 4rem;
		}

		p {
			margin: 0 0 2em 0;
		}
	}

	&__cv {
		padding: 8rem 6rem 6rem 6rem;
		background-color: lighten($black, 96);
		z-index: -1;
		margin-top: -5rem;
		position: relative;

		@media (max-width: $viewport-md) {
			padding: 8rem 4rem;
		}

		h3 {
			font-size: 2.8rem;
			font-weight: 500;
		}

		ul {
			margin: 0;
			padding: 0;
		}

		li {
			display: block;
			margin: 0 0 1em 0;

			strong {
				font-weight: 600;
			}

			span {
				display: block;
			}
		}
	}
}

@keyframes aboutBlock{
	from{
		transform: translateY(5rem);
		opacity: 0;
	}
	to{
		transform: translateY(0);
		opacity: 1;
	}
}