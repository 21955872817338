.contact {
	margin-top: -6rem;
	margin-bottom: 6rem;
	z-index: 0;
	position: relative;

	&__description {
		padding: 11rem 6rem 6rem 6rem;
		background-color: lighten($black, 96);
		font-size: 2.6rem;
		font-weight: 500;
		text-align: center;

		span {
			display: block;
		}

		a {
			display: inline-block;
			color: $yellow;
			text-decoration: none;
			margin-top: 2rem;
			font-weight: 600;
			position: relative;
			transition: all .4s $easeInOutCubic;

			span {
				position: relative;
				z-index: 1;
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				background-color: $yellow;
				left: 0;
				width: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 0;
				transition: all .4s $easeInOutCubic;
			}

			&:hover {
				color: $white;

				&:before {
					width: 100%;
				}
			}
		}
	}

	&__social {
		display: block;
		margin-top: 6rem;
	}

	&__sociallink {
		padding: 0 .5rem;

		svg path {
			transition: all .15s $easeInOutCubic;
			fill: lighten($black, 30);
		}

		&:hover {

			&:before {
				display: none;
			}

			svg path {
				fill: $yellow;
			}
		}
	}
}