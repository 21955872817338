html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

*:focus {
    outline: none; // remove outline from all focused elements
}

*::selection {
    background: $yellow;
    color: $white;
}

html {
    // Set the base font size for rem sizes
    font-size: 62.5%;

    // Add cross-browser antialiasing
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;

    // Other base styling
    text-rendering: auto;
    -webkit-text-size-adjust: 100%;
    position: relative;
    height: 100%;
    width: 100%;

    @media (max-width: $viewport-sm) {
        font-size: 50%;
    }
}

body {
    position: relative;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    font-family: 'Work Sans', sans-serif;
    font-size: 2rem;
    line-height: 1.6;
    color: lighten($black, 30);
    background-color: $white;

    &.no-scroll {
        overflow: hidden;
    }
}

.row {
    margin-right: 0;
    margin-left: 0;
}