.button {
  display: inline-block;
  padding: 1rem 2rem;
  border: 0.2rem solid $yellow;
  color: $yellow;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  transition: all 0.4s $easeInOutCubic;

  span {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: $yellow;
    left: 0;
    width: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    transition: all 0.4s $easeInOutCubic;
  }

  &:hover {
    color: $white;

    &:before {
      width: 100%;
    }
  }
}
