.introduction {
	width: 100vw;
	height: 100vh;
	max-height: 90rem;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 73vw;
		background-color: lighten($black, 96);
		top: 5rem;
		bottom: 5rem;
		left: 0;
		z-index: -1;
		animation: introBlock 1s $easeInOutCubic;
	}

	&__content {
		max-width: 1200px;

		@media (max-width: $viewport-md) {
			padding: 0 6rem;
		}
	}

	&__header {
		font-size: 5.4rem;
		line-height: 1;
		font-weight: 300;
		margin: 0 0 1.5rem 0;
		animation: introHeader 1s $easeInOutCubic both;
		display: block;
		position: relative;
		color: $white;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -1rem;
			bottom: -1rem;
			left: -1rem;
			right: -1rem;
			background-color: $yellow;
			z-index: -1;
		}

		strong {
			font-weight: 600;
		}
	}

	&__subheader {
		font-weight: 500;
		font-size: 2.6rem;
		display: block;
		margin: 0 0 10rem 0;
		animation: introText 1s $easeInOutCubic .8s both;

		@media (max-width: $viewport-md) {
			margin-bottom: 6rem;
		}
	}

	&__emoticon {
		transition: all .4s $easeInOutCubic;
		display: inline-block;

		&:hover {
			transform: rotate(-90deg);
		}
	}

	&__links {
		animation: introText 1s $easeInOutCubic 1.2s both;

		@media (max-width: $viewport-md) {
			display: block;
			padding-bottom: 4rem;
		}
	}

	&__link {
		font-weight: 600;
		text-decoration: none;
		display: inline-block;
		position: relative;
		transition: all .4s $easeInOutCubic;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			width: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: -1;
			transition: all .4s $easeInOutCubic;
		}

		&:hover {
			color: $white;

			&:before {
				width: 100%;
			}
		}

		&--work {
			color: $green;

			&:before {
				background-color: $green;
			}
		}

		&--about {
			color: $yellow;

			&:before {
				background-color: $yellow;
			}
		}

		&--contact {
			color: $blue;

			&:before {
				background-color: $blue;
			}
		}
	}
}

@keyframes introBlock {
	from {
		transform: translateX(-70vw);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes introHeader {
	from {
		transform: translateX(-30rem);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes introText {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}