@mixin autoWidth() {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

@mixin percentWidth($property, $columns, $count) {
    #{$property}: ((100% / $columns) * $count);
}

@mixin percentOffset($property, $columns, $count) {
    $single-column-width: ((100% / $columns) * 1);
    #{$property}: $single-column-width * $count;
}

@mixin queries($key, $queries) {
    $query: map-get($queries, $key);
    @if ($query==$defaultQuery) {
        @content;
    }
    @else {
        @media screen and (max-width: #{$query}) {
            @content;
        }
    }
}

@mixin columns($selector: $column-selector, $properties: $column-properties, $columns: $column-count, $queries: $queries) {
    @each $size in $sizes {
        $iterator-selector: '';
        @for $i from 0 through $columns {
            @if ($i==0) {
                $iterator-selector: #{$selector}#{$block-delimiter}#{$size};
            }
            @else {
                $iterator-selector: #{$selector}#{$block-delimiter}#{$size}#{$modifier-delimiter}#{$i};
            }
            #{$iterator-selector} {
                @extend %column;
            }
        }
        @include queries($size, $queries) {
            @for $i from 0 through $columns {
                @if ($i==0) {
                    #{$selector}#{$block-delimiter}#{$size} {
                        @content;
                        @include autoWidth();
                    }
                }
                @else {
                    #{$selector}#{$block-delimiter}#{$size}#{$modifier-delimiter}#{$i} {
                        @content;
                        @each $prop in $properties {
                            @include percentWidth($prop, $columns, $i);
                        }
                    }
                }
            }
        }
    }
}

@mixin offsets($selector: $column-selector, $modifier-selector: $offset-modifier, $properties: $offset-properties, $columns: $column-count, $queries: $queries) {
    @each $size in $sizes {
        @for $i from 1 through $columns {
            #{$selector}#{$block-delimiter}#{$size}#{$modifier-delimiter}#{$modifier-selector}#{$modifier-delimiter}#{$i} {
                @extend %column;
            }
        }
        @include queries($size, $queries) {
            @for $i from 1 through $columns {
                #{$selector}#{$block-delimiter}#{$size}#{$modifier-delimiter}#{$modifier-selector}#{$modifier-delimiter}#{$i} {
                    @content;
                    @each $prop in $properties {
                        @include percentOffset($prop, $columns, $i);
                    }
                }
            }
        }
    }
}

@mixin breakpoints($selector, $queries: $queries) {
    @each $size in $sizes {
        #{$selector}#{$modifier-delimiter}#{$size} {
            @include queries($size, $queries) {
                @content;
            }
        }
    }
}

.container-fluid {
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    padding-right: $outer-margin;
    padding-left: $outer-margin;
}

.row {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: $gutter-compensation;
    margin-left: $gutter-compensation;
}

%column {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
}

@include columns();
@include offsets();
@include breakpoints('.start') {
    justify-content: flex-start;
}

@include breakpoints('.center') {
    justify-content: center;
}

@include breakpoints('.end') {
    justify-content: flex-end;
}

@include breakpoints('.top') {
    align-items: flex-start;
}

@include breakpoints('.middle') {
    align-items: center;
}

@include breakpoints('.bottom') {
    align-items: flex-end;
}

@include breakpoints('.around') {
    justify-content: space-around;
}

@include breakpoints('.between') {
    justify-content: space-between;
}

@include breakpoints('.first') {
    order: -1;
}

@include breakpoints('.last') {
    order: 1;
}

@include breakpoints('.reverse') {
    flex-direction: row-reverse;
}
