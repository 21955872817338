.hamburger {
	display: block;
	position: fixed;
	width: 5rem;
	height: 5rem;
	top: 4rem;
	right: 4rem;
	z-index: 3;

	@media (max-width: $viewport-md) {
		top: 2rem;
		right: 2rem;
	}

	&:before {
		content: '';
		position: absolute;
		width: 5rem;
		height: 5rem;
		background-color: lighten($black, 98);
		top: .5rem;
		right: .5rem;
		z-index: 0;
		opacity: 1;
		transition: all .4s $easeInOutCubic;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 8rem;
		background-color: $yellow;
		transition: all .4s $easeInOutCubic;
		z-index: 1;
	}

	&:hover {

		&:after {
			right: 0;
		}

		.hamburger__line:before {
			width: 2rem;
		}
	}

	&__border {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: .2rem solid $yellow;
		box-sizing: border-box;
	}

	&__line {
		width: 2rem;
		height: .2rem;
		background-color: $yellow;
		position: absolute;
		left: 1.5rem;
		transition: all .4s $easeInOutCubic;
		z-index: 2;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: .2rem;
			background-color: $white;
		}

		&--first {
			top: 1.85rem;

			&:before {
				transition: all .3s $easeInOutCubic .4s;
			}
		}

		&--second {
			top: 2.45rem;

			&:before {
				transition: all .4s $easeInOutCubic .3s;
			}
		}

		&--third {
			top: 3.05rem;

			&:before {
				transition: all .2s $easeInOutCubic .5s;
			}
		}
	}

	&--open {

		&:before {
			opacity: 0;
		}

		.hamburger__line--first {
			transform: rotate(-45deg);
			top: 2.45rem;
		}

		.hamburger__line--second {
			transform: translateX(3rem);
			opacity: 0;
		}

		.hamburger__line--third {
			transform: rotate(45deg);
			top: 2.45rem;
		}
	}
}

.navigation {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	transform: translateY(-100vh);
	pointer-events: none;
	transition: all .5s $easeInOutCubic;
	background-color: lighten($black, 98);
	z-index: 2;
	line-height: 1;

	&--open {
		transform: translateY(0);
		pointer-events: all;
	}

	&__list {
		position: relative;
		display: flex;
		width: 100%;
		margin-top: 13rem;
		margin-bottom: 0;
		height: calc(100% - 13rem);
		padding: 0;

		@media (max-width: $viewport-md) {
			flex-direction: column;
		}
	}

	&__item {
		display: block;
		width: calc(100%/3);

		@media (max-width: $viewport-md) {
			width: 100%;
			height: calc(100% / 3);
		}
	}

	&__link {
		color: lighten($black, 60);
		font-size: 4.8rem;
		text-decoration: none;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		letter-spacing: .2em;
		font-weight: 400;
		position: relative;
		transition: all .4s $easeInOutCubic;
		overflow: hidden;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transform: translateY(100%);
			transition: all .4s $easeInOutCubic;
			z-index: -1;
		}

		&:hover {
			color: $white;

			&:after {
				transform: translateY(0);
			}
		}

		&--about {
			&:after {
				background-color: $yellow;
			}
		}

		&--work {
			&:after {
				background-color: $green;
			}
		}

		&--contact {
			&:after {
				background-color: $blue;
			}
		}
	}
}